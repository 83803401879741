import React, { Component } from "react";
import { Link } from "react-router-dom";
import "~/components/footer/footer.css";

class Footer extends Component {
  render() {
    return (
      <div className="footer d-flex justify-content-around">
        <div className="nav-item active">
          <Link to="/" className="nav-link">
            <span>
              <i class="bi bi-house-door-fill"></i>
            </span>
            <span>Trang chủ</span>
          </Link>
        </div>
        <div className="nav-item">
          <Link to="/category" className="nav-link">
            <span>
              <i class="bi bi-currency-exchange"></i>
            </span>
            <span>Hạng mục</span>
          </Link>
        </div>
        <div className="nav-item">
          <Link
            to="/contact"
            onClick={(e) => {
              e.preventDefault();
              const phone = sessionStorage.getItem("phone");
              LiveChatWidget.call("maximize");
              LiveChatWidget.call("set_customer_name", phone);
            }}
            className="nav-link"
          >
            <span>
              <i class="bi bi-headset"></i>
            </span>
            <span>CSKH</span>
          </Link>
        </div>
        <div className="nav-item">
          <Link to="/aboutus" className="nav-link">
            <span>
              <i class="bi bi-buildings-fill"></i>
            </span>
            <span>Giới thiệu</span>
          </Link>
        </div>
        <div className="nav-item">
          <Link to="/profile" className="nav-link">
            <span>
              <i class="bi bi-person-fill"></i>
            </span>
            <span>Của tôi</span>
          </Link>
        </div>
      </div>
    );
  }
}

export default Footer;
