import React, { Component, useEffect } from "react";
import FooterOnly from "~/layouts/FooterOnly/FooterOnly.js";
import Tabs from "~/components/tabs/tabs";
import { useRef } from "react";
import "./contact.css";
import axios from "axios";

function Contact() {
  const phone = sessionStorage.getItem("phone");
  useEffect(() => {
    if (LiveChatWidget) {
      LiveChatWidget.call("maximize");
      LiveChatWidget.call("set_customer_name", phone);
    }
  }, []);
  return <FooterOnly></FooterOnly>;
}

export default Contact;
