import React, { Component } from 'react';
import FooterOnly from '~/layouts/FooterOnly/FooterOnly.js';
import Tabs from '~/components/tabs/tabs';

class Changeauthen extends Component {
  render() {
    return (
        <FooterOnly>
        </FooterOnly>
    );
  }
}

export default Changeauthen;