import React, { Component } from "react";
import FooterOnly from "~/layouts/FooterOnly/FooterOnly.js";
import Tabs from "~/components/tabs/tabs";
import { useSelector, useDispatch } from "react-redux";
import { blogsGetData } from "~/redux/about/actionCreator";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "~/pages/aboutus/aboutus.css";

function AboutUs() {
  const dispatch = useDispatch();
  const { blogs, loading, error } = useSelector((state) => ({
    blogs: state.about.blogs,
    loading: state.about.loading,
    error: state.about.error,
  }));

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(blogsGetData());
  }, [dispatch]);
  return (
    <FooterOnly>
      <div className="aboutus">
        <div className="container">
          <h6>Về chúng tôi</h6>
          <div className="list-item">
            {blogs
              ? blogs.map((blog, index) => {
                  return (
                    <a
                      key={index}
                      href={`/aboutus/${blog.id}`}
                      className="item d-flex justify-content-between align-items-center"
                    >
                      <div className="item-title d-flex">
                        <img src="image/logo-evn.webp" />
                        <p>{blog.title.rendered}</p>
                      </div>
                      <span>
                        <i class="bi bi-chevron-right"></i>
                      </span>
                    </a>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
    </FooterOnly>
  );
}

export default AboutUs;
