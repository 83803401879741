import React, { Component } from 'react';
import '~/components/popup/popup.css'


class Popup extends Component {
  render() {
    return (
        <div>
        </div>
    );
  }
}

export default Popup;