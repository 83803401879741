import React, { Component } from "react";
import "~/components/navigation/navigation.css";
import { Link } from "react-router-dom";

class Navigation extends Component {
  render() {
    return (
      <div className="container">
        <div className="listNavigation">
          <Link to="profile/Recharge " className="item text-center">
            <div className="bgIcon">
              <i class="bi bi-currency-exchange"></i>
            </div>
            <p>Tôi muốn nạp tiền</p>
          </Link>
          <Link to="profile/Withdraw " className="item text-center">
            <div className="bgIcon">
              <i class="bi bi-wallet2"></i>
            </div>
            <p>Tôi muốn rút tiền</p>
          </Link>
          <Link to="profile/dailycheck " className="item text-center">
            <div className="bgIcon">
              <i class="bi bi-pencil-square"></i>
            </div>
            <p>Điểm danh</p>
          </Link>
          <Link to="profile/ourproject " className="item text-center">
            <div className="bgIcon">
              <i class="bi bi-bar-chart-line-fill"></i>
            </div>
            <p>Đầu tư của tôi</p>
          </Link>
          <Link to="notification" className="item text-center">
            <div className="bgIcon">
              <i class="bi bi-volume-up-fill"></i>
            </div>
            <p>Thông báo</p>
          </Link>
          <Link to="blogs" className="item text-center">
            <div className="bgIcon">
              <i class="bi bi-newspaper"></i>
            </div>
            <p>Bản tin dự án</p>
          </Link>
          <Link
            to="contact"
            onClick={(e) => {
              e.preventDefault();
              const phone = sessionStorage.getItem("phone");
              LiveChatWidget.call("maximize");
              LiveChatWidget.call("set_customer_name", phone);
            }}
            className="item text-center"
          >
            <div className="bgIcon">
              <i class="bi bi-headset"></i>
            </div>
            <p>CSKH</p>
          </Link>
        </div>
      </div>
    );
  }
}

export default Navigation;
