import React, { Component, useEffect } from "react";
import FooterOnly from "~/layouts/FooterOnly/FooterOnly.js";
import Tabs from "~/components/tabs/tabs";
import "~/pages/aboutus/aboutus.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { filterSinglePage } from "~/redux/about/actionCreator";

function AboutDetail() {
  const params = useParams();
  const blog = useSelector((state) => state.blog.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(filterSinglePage(parseInt(params.id, 10)));
  }, [dispatch]);
  if (blog.length > 0) {
    return (
      <FooterOnly>
        <div className="aboutus">
          <div className="container">
            <h6>{blog[0].title.rendered}</h6>
            <div className="content">
              <div
                className="input"
                dangerouslySetInnerHTML={{ __html: blog[0].content.rendered }}
              />
            </div>
          </div>
        </div>
      </FooterOnly>
    );
  }
}

export default AboutDetail;
